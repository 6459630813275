<template>
  <i18n-change />
</template>

<script>
import i18nChange from './i18nChange.vue';

export default {
  components: {
    i18nChange,
  },
};
</script>
